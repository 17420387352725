// include customized bootstrap
@import 'scss/config.scss';

$navbar-color: #212b43;
$primary: #212b43;

html,
body,
#root {
    height: 100%;
}

body {
    background-image: url('../public/background/vejes.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #1d3557;
}
.navbar {
    background: $navbar-color;
}

.pro-sidebar {
    background: $navbar-color !important;
}

.navbar-brand {
    color: white;
}
.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    color: #000133;
}

// .header,
.footer {
    display: flex;
    background: #00022e;
    height: 50px;
    align-items: center;
    justify-content: center;
    color: #fc86aa;
}

.container-box {
    display: flex;
    flex: 1;
}

.main {
    flex: 1;
    background: #f7f5f5;
}

.admin-panel-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
